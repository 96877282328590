@use "../utils/" as *;
.btn {
  &.MuiButtonBase-root {
    padding: 6.25px 24px;
    @include toRem(font-size, 18);
    font-weight: 500;
    border-radius: 8px;
    @include flexbox();
    @include align-items($value: center);
    @include justify-content($value: center);
    text-transform: inherit;
    @include transition(all 0.3s ease-in-out);
    background-position: 100% 0;
    &-primary,
    &.MuiButton-containedPrimary {
      background-image: var(--color-gradient);
      color: var(--color-white);
      &:hover {
        background-image: var(--color-hover-gradient);
        background-size: 300% 100%;
      }
    }
  }
}

.btn-small {
  @include toRem(height, 32);
  padding: 0 15px;
  @include toRem(font-size, 14);
  font-weight: 500;
  text-transform: inherit !important;
}
.btn-primary-outline-300 {
  border: 1px solid var(--color-blue-300);
  color: var(--color-blue-400);
}
