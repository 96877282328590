@use "../utils/" as *;
.form-content {
    .form-group {
        @include toRem(margin-bottom, 16);
    }
    .button {
        @include flexbox();
    }
    .MuiFormControl-root {
        width: 100%;
        border: 1px solid var(--color-gray-200);
        border-radius: 8px;
        .MuiFormLabel-root {
            transform: translate(16px, 10px);
            &.MuiInputLabel-shrink,
            &.Mui-focused {
                transform: translate(16px, -9px);
                background-color: var(--color-white);
            }
        }
        .MuiInputBase-root {
            fieldset {
                border: none;
                display: none;
            }
            input {
                width: 100%;
            }
            .MuiInputBase-input {
                padding: 10px 16px;
            }
        }
    }
}