@use "mixin" as *;
@use "variable" as *;
@use "breakpoint" as *;
.grid {
  @include respond-above(xs) {
    @include grid("grid-template-columns", 1fr, 15px, 15px);
    &__items {
      &.grid1 {
        grid-row: 4/4;
      }
      &.grid2 {
        grid-column: 1/1;
      }
    }
  }
  @include respond-above(sm) {
    @include grid("grid-template-columns", 1fr 1fr, 15px, 15px);
    &__items {
      &.grid1 {
        grid-row: 2/4;
      }
      &.grid2 {
        grid-column: 1/3;
      }
    }
  }
  @include respond-above(md) {
    @include grid("grid-template-columns", 1fr 1fr, 15px, 15px);
    &__items {
      &.grid1 {
        grid-row: 1/4;
      }
      &.grid2 {
        grid-column: 2/3;
      }
    }
  }
  @include respond-above(lg) {
    @include grid("grid-template-columns", 320px 1fr 1fr, 15px, 15px);
    &__items {
      &.grid1 {
        grid-row: 1/3;
      }
      &.grid2 {
        grid-column: 2/4;
      }
    }
  }
  @include respond-above(xl) {
    @include grid("grid-template-columns", 380px 1fr 1fr, 30px, 30px);
  }
}
