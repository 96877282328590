@use "../utils/" as *;
.MuiFormControl-root {
  width: 100%;
  .MuiFormLabel-root {
    @include toRem(font-size, 16);
    color: var(--color-gray-300);
    font-weight: 700;
    &.MuiInputLabel-shrink {
      @include toRem(font-size, 12);
      font-weight: 400;
      color: var(--color-gray-300);
    }
  }
  .MuiInputBase-root {
    input {
      @include toRem(font-size, 16);
      font-weight: 700;
      color: var(--color-black-400);
    }
    &::before {
      border-bottom: 1px solid var(--color-gray-200);
    }
    &:hover {
      &:not(.Mui-disabled):before {
        border-color: var(--color-blue-400) !important;
      }
    }
  }
}

.daterange-picker-inputs {
  @include flexbox();
  border: 1px solid var(--color-gray-200);
  @include toRem(height, 44);
  @include toRem(border-radius, 8);
  padding: 0 16px;
  .react-datepicker-wrapper {
    width: 100%;
    height: 100%;
    background-color: transparent;
    position: relative;
    .react-datepicker__input-container {
      height: 100%;
      input {
        @include toRem(width, 100);
        max-width: 100%;
        @extend .react-datepicker__input-container;
        &::placeholder {
          color: var(--color-gray-300);
        }
      }
    }
    &:first-child {
      &::after {
        content: "-";
        position: absolute;
        right: 4px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
.daterange-picker-inputs-launch {
  @include flexbox();
  border: 1px solid var(--color-gray-200);
  @include toRem(height, 44);
  @include toRem(border-radius, 8);
  padding: 0 16px;
  .react-datepicker-wrapper {
    height: 100%;
    width: 36%;
    background-color: transparent;
    position: relative;
    .react-datepicker__input-container {
      height: 100%;
      input {
        @include toRem(width, 100);
        max-width: 100%;
        @extend .react-datepicker__input-container;
        &::placeholder {
          color: var(--color-gray-300);
        }
      }
    }
    &:first-child {
      &::after {
        content: "-";
        position: absolute;
        right: 8px;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
      }
    }
  }
}
.ml-2 {
  margin-left: 2rem;
}
