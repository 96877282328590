
@use "../utils"  as *;

:root{
    font-family: 'Roboto', sans-serif;
    @each $colorkey , $colorvalue in $colorScheme{
        --color-#{$colorkey} :#{$colorvalue};
    }
    --grid-width:1400px;
    --gutter-gap:1.25rem;
}


