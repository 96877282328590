@use "../utils/" as *;
.login {
  &_main {
    position: relative;
    width: 100%;
    height: 100%;
    background: url(../../../../public/images/bg.png) no-repeat;
    background-size: cover;
  }
  &_content {
    @include flexbox();
    @include align-items($value: center);
    @include justify-content($value: center);
    @include flex-direction($value: column);
    height: 100%;
    width: 100%;
    position: relative;
    .card {
      background-color: var(--color-white);
      box-shadow: 0px 4px 32px rgba(22, 38, 66, 0.35);
      border-radius: 8px;
      padding: 48px;
      @include toRem(max-width, 400);
      width: 100%;
      .form {
        width: 100%;
        position: relative;
        margin-top: 30px;
        .form-group {
          margin-bottom: 15px;
        }
        .button {
          margin-top: 32px;
        }
      }
    }
    .bottom-text {
      color: var(--color-white);
      margin-top: 25px;
      .dim {
        font-weight: 300;
      }
      .focused {
        margin-left: 18%;
      }
    }
  }
}
