@use "../utils/" as *;
.MuiModal-root {
  .clinicModal,
  .MuiBox-root {
    padding: 24px 20px;
    @include toRem(width, 500);
    border: 0;
    @include toRem(border-radius, 16);
    &__header {
      @include flexbox();
      @include align-items($value: center);
      @include justify-content($value: space-between);
      @include toRem(margin-bottom, 20);
    }
  }
  .table-responsive {
    @include toRem(max-height, 400);
    overflow-y: auto;
    overflow-x: hidden;
    .table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      tr {
        @include toRem(height, 43);
        &:not(:last-child) {
          border-bottom: 1px solid var(--color-gray-100);
        }
        td {
          &.dd_table_title {
            @include toRem(font-size, 16);
            font-weight: 700;
            color: var(--color-pure-black);
          }
          &.dd_table_title_sm {
            @include toRem(font-size, 16);
            color: var(--color-gray-300);
            text-align: right;
            padding-right: 10px;
          }
        }
      }
    }
  }
  .button {
    @include toRem(margin-top, 20);
  }
}
