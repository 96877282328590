@use "../utils/" as *;
html,
body,
#root {
  height: 100%;
  overflow-y: hidden;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--color-gray-200);
  @include scrollbars(5px, var(--color-blue), var(--color-gray-200));
}
.d-flex {
  @include flexbox();
}
.justify-content-end {
  justify-content: flex-end;
}
.main {
  width: 100%;
  height: 100%;
  @include flexbox();
  @include flex-direction($value: column);
  position: relative;
  &__wrapper {
    flex: 1;
    background-color: var(--color-white-500);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.custom-container {
  max-width: 1200px;
  margin: auto;
  @include toRem(padding-left, 15);
  @include toRem(padding-right, 15);
}
