@use "../utils/" as *;
.dashboard {
  position: relative;
  width: 100%;
  background-color: var(--color-gray-50);
  padding: 40px 0;
  &__wrapper {
    position: relative;
    width: 100%;
    &--filter {
      background-color: var(--color-white);
      box-shadow: var(--color-box-shadow);
      border-radius: 8px;
      padding: 18px 24px;
      @include flexbox();
      @include justify-content($value: space-between);
      @include align-items($value: center);
      @include flex-direction($value: column);
      @include respond-above(md) {
        @include flex-direction($value: row);
      }
      .filter-input {
        @include flexbox();
        @include align-items($value: center);
        flex: auto;
        width: 100%;
        @include toRem(max-width, 676);
        @include flex-wrap($value: wrap);
        @include toRem(margin-right, 0);
        @include respond-above(md) {
          @include toRem(margin-right, 16);
        }
        @include respond-above(sm) {
          @include flex-wrap($value: nowrap);
        }
        .MuiFormControl-root {
          &:not(:first-child) {
            @include toRem(margin-top, 15);
            @include toRem(margin-left, 0);
            @include respond-above(sm) {
              @include toRem(margin-left, 16);
              @include toRem(margin-top, 0);
            }
            .MuiInputBase-root {
              .MuiInputBase-input {
                // @include toRem(width, 320);
                @include toRem(max-width, 320);
              }
            }
          }
        }
        .materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
          top: 70px;
        }
      }
      .filter-button {
        @include toRem(margin-top, 20);
        width: 100%;
        .btn {
          width: 100%;
        }
        @include respond-above(md) {
          @include toRem(margin-top, 0);
          width: auto;
        }
      }
    }
    &--user-info {
      position: relative;
      padding: 24px 0;
      .avatar-name {
        @include flexbox();
        @include align-items($value: center);
        .avatar {
          position: relative;
          @include toRem(margin-right, 16);
          .circle {
            @include flexbox();
            @include justify-content($value: center);
            @include align-items($value: center);
            @include toRem(min-width, 80);
            @include toRem(min-height, 80);
            @include toRem(max-width, 80);
            @include toRem(max-height, 80);
            @include toRem(border-radius, 80);
            background-color: var(--color-light-blue);
          }
        }
        .name {
          @include flexbox();
          @include flex-direction($value: column);
          .title {
            @include toRem(font-size, 22);
            color: var(--color-pure-black);
            @include toRem(line-height, 26);
          }
          .date,
          p {
            @include toRem(font-size, 14);
            color: var(--color-gray-300);
          }
        }
      }
    }
    &--card {
      position: relative;
      .MuiGrid-root {
        .dd_card_title {
          @include toRem(font-size, 22);
          color: var(--color-pure-black);
          @include toRem(line-height, 26);
          @include toRem(margin-bottom, 20);
          font-weight: 400;
          @include flexbox();
        }
        .card {
          box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.07);
          border-radius: 8px;
          background-color: var(--color-white);
          @include toRem(padding, 24);
          @include toRem(min-height, 128);
          height: 100%;
          .text-icon {
            width: 100%;
            height: 100%;
            @include flexbox();
            @include align-items($value: center);
            @include justify-content($value: space-between);
            .text {
              position: relative;
              .card_text {
                @include toRem(font-size, 36);
                color: var(--color-pure-black);
                @include toRem(line-height, 42);
                font-weight: 700;
              }
              .card_text_sm {
                @include toRem(font-size, 12);
                color: var(--color-gray-300);
                @include toRem(line-height, 20);
              }
            }
          }
        }
        .table {
          border-collapse: collapse;
          border-spacing: 0;
          width: 100%;
          tr {
            @include toRem(height, 43);
            &:not(:last-child) {
              border-bottom: 1px solid var(--color-gray-100);
            }
            td {
              &.dd_table_title {
                @include toRem(font-size, 16);
                font-weight: 700;
                color: var(--color-pure-black);
              }
              &.dd_table_title_sm {
                @include toRem(font-size, 16);
                color: var(--color-gray-300);
                text-align: right;
              }
              .button {
                @include toRem(margin-top, 20);
              }
            }
          }
        }
      }
    }
  }
}
.materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
  position: absolute !important;
}
.dispositive-progress {
  position: relative;
  @include toRem(margin-top, 24);
  .light-bg {
    background-color: rgba(218, 241, 250, 0.5);
    padding: 16px;
  }
  .label-progressbar {
    width: 100%;
    .label {
      @include toRem(font-size, 16);
      color: var(--color-gray-400);
      @include toRem(line-height, 20);
    }
    .progressbar {
      @include flexbox();
      @include align-items($value: center);
      @include justify-content($value: flex-end);
      @include toRem(font-size, 18);
      color: var(--color-black);
      font-weight: 700;
      .progress-line {
        @include flexbox();
        @include toRem(height, 10);
        @include toRem(margin-left, 12);
        position: relative;
        @include toRem(min-width, 130);
        @include toRem(border-radius, 6);
        overflow: hidden;
        .MuiLinearProgress-root {
          width: 100%;
          height: 100%;
          background-color: var(--color-white);
          .MuiLinearProgress-bar {
            background-color: var(--color-blue-300);
          }
        }
      }
    }
  }
}

.center {
  display: flex !important;
  margin: auto;
  margin-top: 20%;
}
