@use "../utils/" as *;
.header {
  position: relative;
  .logo_header {
    @include flexbox();
    img {
      width: 100%;
    }
  }
  .logout {
    cursor: pointer;
  }
  nav {
    background-image: var(--color-gradient);
    @include flexbox();
    @include align-items($value: center);
    @include justify-content($value: space-between);
    @include toRem(padding, 24);
  }   
}
  