@use "../utils/" as *;
.lounchpad {
    position: relative;
    width: 100%;;
    height: 100%;
    @include flexbox();
    @include align-items($value: center);
    @include justify-content($value: center);
    &__form {
        @include toRem(max-width, 368);
        width: 100%;
        @include toRem(padding, 24);
        background-color: var(--color-white);
        box-shadow: var(--color-box-shadow);
        border-radius: 8px;
        &--heading {
            @include toRem(font-size, 22);
            color: var(--color-pure-black);
            @include toRem(margin-bottom, 16);
        }
        &--content {
            position: relative;
        }
    }
}